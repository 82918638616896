import {
  Box,
  Chip,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  linearProgressClasses,
  Paper,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { skillMap, getSkillSets } from "../../db/Skills";
import { TSkills } from "../../contracts/SkillTypes";
import { ShortSkillComponent } from "./ShortSkill";
import { useEffect } from "react";
import { scrollTop } from "../../utils/scrollTop";
import { getProjectList } from "../../db/Experiences";
import { getExpirence } from "../../utils/getExpirence";

const StyledDivider = styled(Divider)(({ theme }: { theme: Theme }) => ({
  padding: "10px 0px",
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  marginTop: "10px",
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: purple[100],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    background: `repeating-linear-gradient( 45deg,${purple[900]},${purple[900]} 10px,${purple[200]} 10px,${purple[200]} 20px )`,
  },
}));

export const SkillPage = () => {
  const params = useParams<{ skillid: string }>();

  const skillO = skillMap[params.skillid as TSkills];

  useEffect(() => {
    scrollTop();
  });

  return (
    <Grid container direction={"column"} style={{ maxWidth: "900px" }}>
      <Grid item>
        <Grid
          container
          flexGrow={1}
          justifyContent={"space-between"}
          spacing={2}
          sx={{ padding: "0 15px 0 15px" }}
        >
          {getSkillSets().map((item) => (
            <Grid item xs={2.3} sm={1}>
              <ShortSkillComponent
                {...item}
                isSelected={item.skillId === params.skillid}
              ></ShortSkillComponent>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Paper style={{ padding: "30px", margin: "20px" }}>
        <Grid item>
          <Grid container alignItems={"center"}>
            <Grid item xs={3}>
              <Grid container justifyContent={"center"}>
                <Icon style={{ width: 70, height: 70 }}>
                  <img
                    alt={skillO.skillId}
                    src={skillO.logo}
                    height={70}
                    width={70}
                  />
                </Icon>
              </Grid>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6">
                {skillO.skill + getExpirence(skillO.startDate, new Date())}
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={skillO.confidenceLevel ?? 0}
              />
              <Typography variant="caption">Confidence Level</Typography>
            </Grid>
          </Grid>
        </Grid>

        <StyledDivider></StyledDivider>

        {skillO.skillDescription?.map((skillDesc) => (
          <Grid item>
            <Grid container alignItems={"center"}>
         
                <Grid
                  container
                  style={{ margin: "20px 0 20px 0" }}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">{skillDesc.group}</Typography>
                  </Grid>
                </Grid>
            </Grid>
            <Grid container justifyItems="left">
              {skillDesc.descriptionArray.map((item, index) => (
                  <Chip sx={{margin: .7}} label={item} />
              ))}
            </Grid>
          </Grid>
        ))}
      </Paper>

      {(getProjectList(params.skillid || "").length>0) && (<Paper style={{ padding: "30px", margin: "20px" }}>
      <Typography variant="h6">Project List</Typography>
        <Box sx={{mt: 2}}>
          {getProjectList(params.skillid || "").map((projectName) => (
            
              <Chip sx={{margin: .7}} component="span" label={projectName} />
          ))}
        </Box>
      </Paper>)}
    </Grid>
  );
};
