import { ISkill, TSkills } from "../contracts/SkillTypes";
import awslogo from "../assets/img/aws-logo.svg";
import pythonlogo from "../assets/img/python-logo.png";
import linuxlogo from "../assets/img/linux-logo.png";
import csharp from "../assets/img/csharp-logo.png";
import sqllogo from "../assets/img/sql-logo.png";
import dotnetlogo from "../assets/img/dotnet-logo.svg";
import dockerlogo from "../assets/img/docker-logo.png";
import k8s from "../assets/img/k8s.svg";
import datamininglogo from "../assets/img/datamining-logo.png";
import gitlogo from "../assets/img/git-logo.png";

export const skillMap: { [key in TSkills]: ISkill } = {
  CS: {
    skill: "C#",
    color: "expert",
    skillId: "CS",
    logo: csharp,
    startDate: new Date(2015, 2, 15, 0, 0, 0, 0),
    confidenceLevel: 85,
    skillDescription: [
      {
        group: "C# API",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [
          "Meta programing",
          "Pattern matching",
          "Exception fitering",
          "Caller information",
          "Static imports",
          "Asyncronous programing",
          "Static Local function",
          "Asyncronous Streams",
          "TPL",
          "Generators",
          "Closures",
          "Records",
          "Anonmous Functions and Types",
          "dynamic",
          "Reflections",
          "CLI",
          "Unit Test",
          "Performance Analysis",
        ],
      },
    ],
  },
  DC: {
    skill: "Dotnet Core",
    color: "proficient",
    skillId: "DC",
    logo: dotnetlogo,
    startDate: new Date(2020, 2, 15, 0, 0, 0, 0),
    confidenceLevel: 70,
    skillDescription: [
      {
        group: "Console and Web API",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [
          "Dependency Injection Container",
          "Middleware",
          "YARP",
          "Serilog",
          "Unit Test",
          "Authentication",
          "Exception Handling",
        ],
      },
    ],
  },
  AWS: {
    skill: "AWS",
    color: "expert",
    skillId: "AWS",
    logo: awslogo,
    startDate: new Date(2019, 1, 15, 0, 0, 0, 0),
    confidenceLevel: 65,
    skillDescription: [
      {
        group: "Serverless",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [
          "AWS Lambda",
          "DynamoDB",
          "API Gateway",
          "IAM",
          "AWS SAM",
          "Fargate",
          "Route53",
          "Clould Front",
          "SNS",
          "SQS",
        ],
      },
      {
        group: "EKS (Self Learning)",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [],
      },
    ],
  },
  K8S: {
    skill: "Kubernetes",
    color: "proficient",
    skillId: "K8S",
    logo: k8s,
    startDate: new Date(),
    confidenceLevel: 45,
    skillDescription: [
      {
        group: "EKS (Self Learning)",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: ["Helm", "Ingress", "Networking", "Architecture"],
      },
    ],
  },
  DO: {
    skill: "Docker",
    color: "proficient",
    skillId: "DO",
    logo: dockerlogo,
    startDate: new Date(2018, 3, 15, 0, 0, 0, 0),
    confidenceLevel: 75,
    skillDescription: [
      {
        group: "Image Creation",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [
          "Multi stage image",
          "Debugging",
          "Networking",
          "Architecture",
        ],
      },
    ],
  },
  PY: {
    skill: "Python",
    color: "expert",
    skillId: "PY",
    logo: pythonlogo,
    startDate: new Date(2017, 1, 15, 0, 0, 0, 0),
    confidenceLevel: 80,
    skillDescription: [
      {
        group: "Features",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [
          "Moudule Setup",
          "Virtual Env",
          "OOPs",
          "jupyter notebook",
        ],
      },
      {
        group: "Framework and Lib",
        repoLink: "https://github.com/Satendra1729",
        descriptionArray: [
          "Scrapy",
          "BeautifulSoup",
          "Cherrypy",
          "Click",
          "Pandas",
        ],
      },
    ],
  },
  SQL: {
    skill: "SQL",
    color: "proficient",
    skillId: "SQL",
    logo: sqllogo,
    confidenceLevel: 30,
    startDate: new Date(2015, 2, 15, 0, 0, 0, 0),
  },
  DM: {
    skill: "Datamining",
    color: "expert",
    skillId: "DM",
    logo: datamininglogo,
    confidenceLevel: 90,
    startDate: new Date(2017, 1, 15, 0, 0, 0, 0),
    skillDescription: [
      {
        group: "Data Processing",
        repoLink: "https://github.com/satendra1729",
        descriptionArray: [
          "Pdf parsing",
          "Html Parsing",
          "Safe Parsing Rules",
          "Data Pipeline",
          "Data Standarization",
          "Data Cleaning",
          "Data Validation"
        ],
      },
    ],
  },
  GIT: {
    skill: "Git",
    color: "proficient",
    skillId: "GIT",
    logo: gitlogo,
    confidenceLevel: 90,
    startDate: new Date(2017, 1, 15, 0, 0, 0, 0),
  },
  LX: {
    skill: "Linux",
    color: "proficient",
    skillId: "LX",
    logo: linuxlogo,
    confidenceLevel: 50,
    startDate: new Date(2017, 1, 15, 0, 0, 0, 0),
    skillDescription: [{
      group : "Adminstration",
      repoLink: "https://github.com/satendra1729",
      descriptionArray : ["user managment","file system","systemd","unit file","bash"]
    },{
      group : "Utility",
      repoLink : "https:github.com/satendra1729",
      descriptionArray : ["bash basic scripting","vim","man","sed"] 
    }]
  },
};

export const getSkillSets = () => {
  return Object.keys(skillMap).map((skill) => skillMap[skill as TSkills]);
};
