import { createTheme } from "@mui/material/styles";

// import { green, purple,blueGrey } from '@mui/material/colors';
// import { Shadows } from '@mui/material/styles/shadows';

export const theme = createTheme({
  //shadows: Array(25).fill("none") as Shadows,
  // palette: {
  //   primary: {
  //     main: '#3f51b5',
  //   },
  //   secondary: {
  //     main: '#f50057',
  //   },
  // },
});
