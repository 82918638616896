import * as React from "react";
import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/home")
  }, [navigate]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-65px",
        height: "100%"
      }}
    >
      <CircularProgress />
    </div>
  );
};
